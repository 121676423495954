import { Icon } from '/features/buildingBlocks/Icon'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Card.css'

import arrowIcon from '/images/icons/arrow.raw.svg'

export function Card({ children, href, dataX, dataXContext }) {
  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  return (
    <a
      data-style-context='light'
      data-x={dataX}
      data-x-context={dataXContext}
      className={styles.component}
      {...{ href }}
    >
      <div className={styles.content}>
        {children}
      </div>
      {viewportMd && (
        <div className={styles.icon}><Icon icon={arrowIcon} /></div>
      )}
    </a>
  )
}
