import { handleResponse } from '/machinery/handleResponse'

export async function fetchWithResponseHandler(url, options = {}) {
  const response = await fetch(url, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    ...options
  })

  return handleResponse(response)
}
