import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { useListDropdown } from '/machinery/useListDropdown'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { useElementSize } from '@kaliber/use-element-size'
import { trackFilterInteractionMultiValue, trackFilterInteraction } from '/analytics/user-interaction'
import { updateFilterValue } from '/machinery/updateFilterValue'
import iconCheck from '/images/icons/check.raw.svg'
import chevronIcon from '/images/icons/chevron-top.raw.svg'
import iconCross from '/images/icons/icon-cross.raw.svg'

import styles from './OverviewFilters.css'

export function OverviewFilters({ onFilterChange, filtersAndValues, onReset }) {
  const filtersSorted = React.useMemo(
    () => Object.fromEntries(
      Object.entries(filtersAndValues).sort(([, a], [, b]) => a.display_order - b.display_order)
    ),
    [filtersAndValues]
  )

  const activeFilters = mapActiveFilters({ filtersAndValues })

  return (
    <div className={styles.component}>
      {Boolean(activeFilters.length) && (
        <ActiveFilters {...{ onFilterChange, activeFilters, onReset }} />
      )}

      {Object.entries(filtersSorted)
        .filter(([, { display }]) => display !== 'none')
        .map(([filterId, { options, value }]) => {
          const isInitialOpen =
            Object.keys(filtersAndValues).length === 1 ||
            Boolean((value && (typeof value === 'string' ||
              Boolean(Array.isArray(value) && value.length > 0))))

          return (
            <FilterGroup
              key={filterId}
              layoutClassName={styles.filterGroupLayout}
              options={filterId === 'tag' ? sortFilterValuesTotalCount(options) : sortFilterValuesTitle(options)}
              {...{ filterId, onFilterChange, isInitialOpen, value }}
            />
          )
        })}
    </div>
  )

  function sortFilterValuesTitle(values) {
    return values.sort((a, b) => a.label.localeCompare(b.label))
  }

  function sortFilterValuesTotalCount(values) {
    return values.sort((a, b) => b.total - a.total)
  }

  function mapActiveFilters({ filtersAndValues }) {
    return Object.entries(filtersAndValues).map(([id, filter]) => {
      const activeOptions = filter.options?.filter(option => filter.value.includes(option.id))
      return activeOptions?.length ? [id, activeOptions, filter.value] : undefined
    }).filter(filter => filter !== undefined)
  }
}

function ActiveFilters({ activeFilters, onReset, onFilterChange }) {
  const { __ } = useTranslate()
  return (
    <div className={styles.componentActiveFilters}>
      <div className={styles.activeFiltersHeader}>{__`selected-filters-title`}</div>
      <div className={styles.activeFilterPills}>
        {activeFilters.map(([filterId, options, value]) => (
          options.map(option => (
            <button
              className={styles.activeFilterPill}
              key={option.id}
              type="button"
              aria-label={__({ filterName: option.label })`reset-filter-label`}
              onClick={() => handleFilterRemoval(filterId, value, option.id, option.subFilter)}
            >
              {option.label}
              <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
                <Icon icon={iconCross} />
              </span>
            </button>
          ))
        ))}

        <button
          className={styles.resetFiltersPill}
          type="button"
          aria-label={__`reset-filters-label`}
          onClick={onReset}
        >
          {__`reset-filters-label-pill`}
          <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
            <Icon icon={iconCross} />
          </span>
        </button>
      </div>
    </div>
  )

  function handleFilterRemoval(filterId, value, id, subFilter = undefined) {


    if (filterId === 'country') {
      onFilterChange({ [filterId]: null })
    } else if (subFilter) {
      const flattenedSubfilterOptions = subFilter?.options?.map(({ id }) => id) || []
      const filteredSubfilterValues = subFilter?.value?.filter(x => !flattenedSubfilterOptions.includes(x))
      onFilterChange({
        [filterId]: value.filter(x => x !== id),
        [subFilter.filterId]: filteredSubfilterValues
      })
    } else {
      onFilterChange({
        [filterId]: value.filter(x => x !== id),
      })
    }
  }
}

function FilterGroup({ layoutClassName, options, filterId, isInitialOpen, onFilterChange, value }) {
  const [isOpen, setOpen] = React.useState(isInitialOpen)
  const { size: { height }, ref: innerRef } = useElementSize()

  const { parentHandlers, childHandlers } = useTabNavigation({ options, onOpenChange: setOpen })
  const id = React.useId()

  return (
    <fieldset
      role='group'
      aria-labelledby={`field-label-${id}`}
      className={cx(styles.componentFilterGroup, layoutClassName)}
      {...parentHandlers}
    >
      <FilterHeader
        onClick={() => setOpen(!isOpen)}
        layoutClassName={styles.filterHeaderLayout}
        {...{ id, isOpen, filterId }}
      />

      <div
        className={styles.expandContainer}
        style={{ height: isOpen ? height + 'px' : 0 }}
        {...childHandlers}
      >
        <div ref={innerRef}>
          {filterId === 'country' && (
            <FilterOptionsCountry {...{ id, options, value, isOpen }} onFilterChange={handleCountryChange} />
          )}

          {filterId === 'tag' && (
            <FilterOptionsPill {...{ filterId, options, value, isOpen }} onFilterItemChange={handlePillChange} />
          )}

          {Boolean(filterId !== 'country' && filterId !== 'tag') && (
            <FilterOptionsCheckbox {...{ filterId, options, value, isOpen }} onFilterItemChange={handleCheckboxChange} />
          )}
        </div>
      </div>
    </fieldset>
  )

  function handleCountryChange(country) {
    trackFilterInteraction({ name: 'country', value: country, inputType: 'single_select' })
    onFilterChange({ country })
  }

  function handlePillChange({ filterId, id }) {
    updateAndTrackItemChange({ type: 'pill', filterId, id })
  }

  function handleCheckboxChange({ filterId, id, value, extraFilters = undefined }) {
    const type = 'checkbox'
    const updatedFilterValue = updateFilterValue({ value, filterId, optionId: id })

    trackFilterInteractionMultiValue({
      name: filterId,
      value: id,
      inputType: type,
      selected: updatedFilterValue.includes(id),
    })

    onFilterChange({
      [filterId]: updatedFilterValue,
      ...extraFilters
    })
  }

  function updateAndTrackItemChange({ type, filterId, id }) {
    const updatedFilterValue = updateFilterValue({ value, filterId, optionId: id })

    trackFilterInteractionMultiValue({
      name: filterId,
      value: id,
      inputType: type,
      selected: updatedFilterValue.includes(id),
    })

    onFilterChange({
      [filterId]: updatedFilterValue,
    })
  }
}

function useTabNavigation({ onOpenChange, options }) {
  return {
    parentHandlers: {
      onKeyDown: e => {
        if (e.key === 'Tab' && e.shiftKey) onOpenChange(false)
        if (e.currentTarget.contains(e.relatedTarget)) {
          onOpenChange(false)
        }
      }
    },
    childHandlers: {
      onKeyDown: e => {
        const isLastItem = e.target?.id === options[options.length - 1]?.id
        if (e.key === 'Tab' && isLastItem) {
          e.stopPropagation()
          onOpenChange(x => !x)
        }
      }
    }
  }
}

function FilterOptionsCountry({ id, options, value, isOpen, onFilterChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFilterOptionsCountry} data-x='select-country'>
      <Select
        ariaLabelledById={id}
        tabIndex={isOpen ? 0 : -1}
        onChange={onFilterChange}
        placeholder={__`filter-country-placeholder`}
        layoutClassName={styles.selectLayout}
        {...{ options, value }}
      />
    </div>
  )
}

function FilterOptionsCheckbox({ filterId, options, value, isOpen, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsCheckbox}>
      {options.map(({ id, label, subFilter }) => (
        <FilterValueItem
          key={id}
          tabIndex={isOpen ? 0 : -1}
          onChange={onFilterItemChange}
          layoutClassName={styles.filterValueItemLayout}
          {...{ filterId, id, label, value, subFilter }}
        />)
      )}
    </div>
  )
}

function FilterOptionsPill({ filterId, options, value, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsPill}>
      {options.map(({ id, label }) => (
        <Pill
          onChange={() => onFilterItemChange({ filterId, id })}
          checked={value.includes(id)}
          key={id}
          {...{ filterId, id, label }}
        />
      ))}
    </div>
  )
}

function FilterHeader({ id, onClick, isOpen, filterId, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      className={cx(styles.componentFilterHeader, layoutClassName)}
      aria-expanded={isOpen}
      data-x='open-filter-dropdown'
      {...{ onClick }}
    >
      <legend id={`field-label-${id}`} className={styles.filterHeader}>
        {__`filter-${filterId.replace('_', '-')}-title`}
      </legend>
      <span className={cx(styles.dropdownChevron, isOpen && styles.rotate)}>
        <Icon icon={chevronIcon} />
      </span>
    </button>
  )
}
function FilterValueItem({ filterId, id, label, onChange, tabIndex, value, subFilter = undefined, layoutClassName = undefined }) {
  const checked = value?.includes(id)

  return (
    <div className={layoutClassName}>
      <Checkbox onChange={handleChange} {...{ label, checked, id, tabIndex }} />
      {
        Boolean(subFilter?.options?.length && checked) &&
        <FilterOptionsCheckbox filterId={subFilter.filterId} options={subFilter.options} value={subFilter.value} onFilterItemChange={onChange} isOpen />
      }
    </div>
  )

  function handleChange() {
    if (subFilter?.options) {
      const flattenedSubfilterOptions = subFilter.options?.map(({ id }) => id) || []
      const filteredSubfilterValues = subFilter.value?.filter(x => !flattenedSubfilterOptions.includes(x))
      onChange({ value, filterId, id, extraFilters: { [subFilter.filterId]: filteredSubfilterValues } })
    } else {
      onChange({ value, filterId, id })
    }

  }
}

function Pill({ checked, id, label, onChange }) {
  return (
    <label htmlFor={id} className={cx(styles.componentPill, checked && styles.pillChecked)}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id }} />
      {label}
    </label>
  )
}

function Checkbox({ checked, id, label, tabIndex, onChange }) {
  return (
    <label htmlFor={id} className={styles.componentCheckbox}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id, tabIndex }} />
      <div className={styles.checkboxLabel}>
        <div className={styles.checkboxLabelMain}>{label}</div>
      </div>
      <span className={cx(styles.checkboxIndicator, checked && styles.checkboxIndicatorChecked)}>
        {checked && <Icon icon={iconCheck} />}
      </span>
    </label>
  )
}

function CheckboxInput({ onChange, checked, id, tabIndex = undefined, layoutClassName = undefined }) {
  return (
    <input
      type='checkbox'
      data-x='apply-filter'
      className={cx(styles.componentCheckboxInput, layoutClassName)}
      {...{ onChange, checked, id, tabIndex }}
    />
  )
}

function Select({ value, onChange, options, placeholder, tabIndex, ariaLabelledById = undefined, layoutClassName }) {
  const selectedIndex = options.findIndex(x => x.value === value)
  const valueLabel = options.find(x => x.id === value)?.label

  const {
    isOpen,
    setIsOpen,
    context,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    activeIndex,
  } = useListDropdown({ selectedIndex })

  return (
    <>
      <button
        type='button'
        aria-labelledby={ariaLabelledById}
        className={cx(styles.select, !valueLabel && styles.selectNoValue, layoutClassName)}
        {...getReferenceProps({ onClick: () => setIsOpen(!isOpen) })}
        {...{ tabIndex }}
      >
        <span>{valueLabel ?? placeholder}</span>
        <span className={cx(styles.selectChevron, isOpen && styles.selectChevronOpen)}><Icon icon={chevronIcon} /></span>
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager {...{ context }}>
            <ul className={styles.selectDropdown} {...getFloatingProps()}>
              {[{ id: null, label: placeholder }, ...options].map(({ id, label }, i) => (
                <li
                  key={id}
                  className={cx(styles.selectOption)}
                >
                  <button
                    {...getItemProps(i, label, {
                      onClick: () => {
                        onChange(id)
                        setIsOpen(false)
                      }
                    })}
                    className={cx(styles.selectOptionButton)}
                    data-active={i === activeIndex}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
