import { Icon } from '/features/buildingBlocks/Icon'
import { PublishedDate } from './PublishedDate'
import { useTranslate } from '/machinery/I18n'

import ShowRewardCalculatorPortalFlowButton from './reward-calculator/ShowRewardCalculatorPortalFlowButton.universal'

import iconClock from '/images/icons/clock.raw.svg'
import iconMoney from '/images/icons/euro.raw.svg'
import styles from './Labels.css'

export function Labels({ labels, layoutClassName = undefined }) {
  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {labels?.map(({ title, icon }, i) => (
        <Label key={i} {...{ title, icon }}  />
      ))}
    </ul>
  )
}

export function LabelsJobDetail({ labels, benefitPageRef = undefined, layoutClassName = undefined }) {
  return (
    <ul className={cx(styles.componentJobDetail, layoutClassName)}>
      {labels?.map(({ title, icon, type }, i) => (
        type === 'salary'
          ? <LabelWithSalaryModal key={i} {...{ title, type, benefitPageRef }}  />
          : <Label key={i} {...{ title, icon }}  />
      ))}
    </ul>
  )
}

export function LabelsWithDate({ labels, date, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <ul className={cx(styles.componentWithDate, layoutClassName)}>
      {labels?.map(({ title, icon }, i) => (
        <Label key={i} {...{ title, icon }}  />
      ))}
      <li className={styles.date}>
        <span>{__`vacancy-published-date`} <PublishedDate {...{ date }} /></span>
      </li>
    </ul>
  )
}

function LabelWithSalaryModal({ benefitPageRef, title }) {
  return (
    <li className={styles.componentLabelWithSalaryModal}>
      <ShowRewardCalculatorPortalFlowButton linkRef={benefitPageRef} dataX='link-to-salary-usp' label={title} buttonType='label' />
    </li>
  )
}

function Label({ title, icon = undefined }) {
  return (
    <li className={styles.componentLabel}>
      {icon && <Icon icon={getIcon(icon)} layoutClassName={styles.iconLayout} />}
      <span>{title}</span>
    </li>

  )

  function getIcon(iconName) {
    switch (iconName) {
      case 'time':
        return iconClock
      case 'money':
        return iconMoney
      default:
        return null
    }
  }
}
